// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);

export default class Actielijnen {
	constructor() {
		const mySwiper = new Swiper('.swiper-container', {
			loop: true,
			watchOverflow: true,

			// cssMode does not work in combination with centeredSlides
			// and in my experience cssMode set to true with the styles we're using right now
			// which are needed for the design, does work but has problems
			// like scrolling to the first slide (didn't work on several browsers
			// on serveral OSses)
			// cssMode: true,

			// Don't set slidesPerView at a constant number
			// This can be solved with CSS which is easier to show
			// different numbers of slides depending on the width of the viewport
			slidesPerView: 'auto',
			centeredSlides: true,

			// mousewheel: {
			// 	invert: true,
			// 	releaseOnEdges: true
			// },

			// Don't use spaceBetween but solve this with CSS
			// CSS is more flexible and we can use relative units
			// spaceBetween: 30,

			pagination: {
				el: '.swiper-pagination',
				clickable: true,
				type: 'bullets'
			},

			// Navigation arrows
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}
}