import $ from "jquery";

export default class ProjectForm {

    constructor() {

        $(document).ready(() => {
            this.loadTooltips();
            this.initMap();

            this.initDragDrop();
        });


    }


    initMap() {

        this.map = L.map('project_form_map-map', {minZoom: 7, maxZoom: 13, worldCopyJump: true, scrollWheelZoom: false});
        this.markerLayer;
        this.markers=[];

        L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions"  target="_blank">CARTO</a>',
            maxZoom: 14
        }).addTo(this.map);

        this.defaultIcon = L.icon({
            iconUrl: '/dist/images/node_modules/leaflet/dist/images/marker-icon.png',
            shadowUrl: '',
            iconSize:     [25, 41], // size of the icon
            shadowSize:   [0, 0], // size of the shadow
            //iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
            shadowAnchor: [0, 0],  // the same for the shadow
            //popupAnchor:  [1, -27] // point from which the popup should open relative to the iconAnchor
        });

        this.editableLayers = new L.FeatureGroup();
        this.map.addLayer(this.editableLayers);

        this.drawPluginOptions = {
            position: 'topright',
            draw: {
                polyline: false,
                polygon: {
                    shapeOptions: {
                        color: '#006F9E'
                    },

                },
                circle: false, // Turns off this drawing tool
                circlemarker: false,
                rectangle: false,
                marker: {
                    icon: this.defaultIcon
                },
            },
            edit: false
        };

        this.editPluginOptions = {
            position: 'topright',
            draw: false,
            edit: {
                featureGroup: this.editableLayers, //REQUIRED!!
                remove: {}
            }
        };

        // Initialise the draw control and pass it the FeatureGroup of editable layers
        let drawControl = new L.Control.Draw(this.drawPluginOptions);
        let editControl = new L.Control.Draw(this.editPluginOptions);
        this.map.addControl(drawControl);

        let editableLayers = this.editableLayers;
        let map = this.map;

        this.map.on('draw:created', function(e) {
            var type = e.layerType,
                layer = e.layer;

            if (type === 'marker') {
                $("#project_form_map").val(layer.toGeoJSON().geometry.coordinates);
            } else {
                $("#project_form_map").val(JSON.stringify(layer.toGeoJSON().geometry.coordinates));
            }

            drawControl.remove(map);
            editControl.addTo(map);

            editableLayers.addLayer(layer);
        });

        this.map.on('draw:editstop', function(e) {
            var type = e.layerType,
                layer = e.layer;

            //in case of edit cancel
            if (typeof type == 'undefined') return;

            if (type === 'marker') {
                $("#project_form_map").val(layer.toGeoJSON().geometry.coordinates);
            } else {
                $("#project_form_map").val(JSON.stringify(layer.toGeoJSON().geometry.coordinates));
            }
        });


        this.map.on("draw:deleted", function(e) {
            let check = Object.keys(editableLayers._layers).length;
            if (check === 0){
                editControl.remove(map);
                drawControl.addTo(map);
            };
            $("#project_form_map").val();
        });




        this.map.setView([52.21158, 5.600498], 7);


    }

    loadTooltips() {
        L.drawLocal.draw.toolbar.buttons.polygon = 'Teken een gebied';
        L.drawLocal.draw.toolbar.buttons.marker = 'Markeer een punt';

        L.drawLocal.draw.handlers.marker.tooltip.start = 'Klik op de kaart om de plaats te markeren';

        L.drawLocal.draw.handlers.polygon.tooltip.start =  'Klik om te starten met het tekenen van het gebied';
        L.drawLocal.draw.handlers.polygon.tooltip.cont = 'Klik om het volgende punt van het gebied te tekenen';
        L.drawLocal.draw.handlers.polygon.tooltip.end = 'Klik op het eerste punt om het gebied te sluiten';

        L.drawLocal.edit.toolbar.buttons.edit = 'Bewerken';
        L.drawLocal.edit.toolbar.buttons.remove = 'Verwijderen';

        L.drawLocal.edit.toolbar.actions.save.text = 'Opslaan';
        L.drawLocal.edit.toolbar.actions.cancel.text = 'Annuleren';
        L.drawLocal.edit.toolbar.actions.clearAll.text = 'Alle punten verwijderen';

        L.drawLocal.edit.handlers.edit.tooltip.text = 'Versleep het punt of de hoeken';
        L.drawLocal.edit.handlers.edit.tooltip.subtext = 'Klik op Annuleren om wijzigingen ongedaan te maken';

        L.drawLocal.edit.handlers.remove.tooltip.text = 'Klik op een punt of gebied om deze te verwijderen';

    }

    initDragDrop() {
        let droppedFiles = [];
        $('.add-another-collection-widget').click(function (e) {
            var list = $($(this).attr('data-list-selector'));
            // Try to find the counter of the list or use the length of the list
            var counter = list.data('widget-counter') || list.children().length;

            // grab the prototype template
            var newWidget = list.attr('data-prototype');
            // replace the "__name__" used in the id and name of the prototype
            // with a number that's unique to your emails
            // end name attribute looks like name="contact[emails][2]"
            newWidget = newWidget.replace(/__name__/g, counter);
            // Increase the counter
            counter++;
            // And store it, the length cannot be used if deleting widgets is allowed
            list.data('widget-counter', counter);

            //add delete button to the widget when it is set
            var newDeleteButton = list.data('widget-delete') || '';
            newWidget += newDeleteButton;

            // create a new list element and add it to the list
            var newElem = $(list.attr('data-widget-tags')).html(newWidget);
            newElem.appendTo(list);

            newElem.find('input.activate-dragdrop').parent().addClass('activate-dragdrop');
            newElem.find('.delete-collection-widget').click(function (e) {
                deleteCollectionWidget(e);
            });

            //set upload draggable
            if (isAdvancedUpload && newElem.find('input[type="file"].activate-dragdrop').length > 0) {
                newElem.addClass('has-advanced-upload');

                newElem.find('div.activate-dragdrop').on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                })
                    .on('dragover dragenter', function() {
                        newElem.find('div.activate-dragdrop').addClass('is-dragover');
                    })
                    .on('dragleave dragend drop', function() {
                        newElem.find('div.activate-dragdrop').removeClass('is-dragover');
                    })
                    .on('drop', function(e) {
                        droppedFiles[counter] = e.originalEvent.dataTransfer.files;
                        showFiles( droppedFiles[counter] );
                        newElem.find('input[type="file"].activate-dragdrop').prop("files", e.originalEvent.dataTransfer.files);
                    });

                var $input    = newElem.find('input[type="file"].activate-dragdrop'),
                    $label    = newElem.find('label.activate-dragdrop'),
                    showFiles = function(files) {
                        $label.text(files.length > 1 ? ($input.attr('data-multiple-caption') || '').replace( '{count}', files.length ) : files[ 0 ].name);
                    };

                $input.on('change', function(e) {
                    showFiles(e.target.files);
                });
            }
        });

        $('.delete-collection-widget').click(function (e) {
            deleteCollectionWidget(e);
        });

        var isAdvancedUpload = function() {
            var div = document.createElement('div');
            return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
        }();

        var deleteCollectionWidget = function(e) {
            e.preventDefault();
            let confirmation = confirm('Weet u zeker dat u deze afbeelding wilt verwijderen?' );
            if (confirmation) {
                if ($(e.target).data('remove')) {
                    eval($(e.target).data('remove')).remove();
                } else {
                    $(e.target).parent().remove();
                }

            }
        }
    }


}