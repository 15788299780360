import QueryString from '../lib/QueryString.js';
import InfiniteScroll from '../lib/InfiniteScroll.js';
import Utils from "../lib/Utils";

export default class SupporterIndex {
    constructor() {

        // Initialize QueryString obj default behaviour
        this.qs = new QueryString();

        // ---------------------[ Infinite Scroll Module ]--------------------- //
        /**
         * Initiate and set up the configuration of infinite scroll.
         */
        let segParam = this.qs.get('segment');
        let segment = segParam && !isNaN(segParam) ? parseInt(segParam) : 0;
        const config = {
            'segment': segment,
            'container': '#supporters-content',
            'autoFill': true,
            'ajaxRoute': '/supporters/data',
            'onSuccess': this.appendSupporters,
            'updateParam': this.qs.updateParam
        }
        const infiniteScroll = new InfiniteScroll(config);
    }

    /**
     * Callback for Ajax infinity scroll request
     */
    appendSupporters(res) {
        res.forEach((supporter) => {
            $('#supporters-content').append(`
                <div class="col-xs-4 col-sm-3 col-md-2">
                    <div class="supporter-item">
                        <a href="/supporter/${supporter.key}">
                            ${supporter.logo}
                        </a>
                    </div>
                </div>
            `)
        });
        // Utils.distributeInsects();
    }
}