import '../scss/index.scss';

import jQuery from 'jquery';
import simpleshare from './lib/simpleshare.jquery';
import inView from 'in-view';
import socialshares from 'socialshares';

// useful polyfills
import smoothscroll from 'smoothscroll-polyfill';

// tooltips/popups
// import tippy, { inlinePositioning } from 'tippy.js';

import './lib/ScreenDetector'; // initializes screensize etc.
import './lib/collapsible';
import CookieConsent from './lib/CookieConsent';
import FormValidator from './lib/FormValidator.js';
import Modals from './lib/Modals.js';
import QueryString from './lib/QueryString.js';
import InfiniteScroll from './lib/InfiniteScroll.js';
import Utils from "./lib/Utils";

import leaflet from 'leaflet';
import leafletMarkerCluster from 'leaflet.markercluster';
import leafletProvider from 'leaflet-providers';
// import leafletDraw from 'leaflet-draw';

// import js for specific pages
import PageHome from './pages/Home.js';
import PageUpdates from './pages/Updates.js';
import PartnerIndex from "./pages/Partners";
import SupporterIndex from "./pages/Supporters";
import Search from "./pages/Search";
import ProjectIndex from "./pages/ProjectIndex";
import ProjectDetail from "./pages/ProjectDetail";
import ProjectForm from "./pages/ProjectForm";
import PilotDetail from "./pages/PilotDetail";
import Actielijnen from "./pages/Actielijnen";

const page = $('body').data('page-key');
switch (page) {
    case 'home':
        new PageHome();
        break;
    case 'partners':
        new PartnerIndex();
        break;
    case 'partner-form':
        $(document).ready(() => {
            const formCategoryChoicePublic = document.getElementById('partner_form_category_0');
            const formCategoryChoicePrivate = document.getElementById('partner_form_category_1');
            const formCategoryChoiceInstitute = document.getElementById('partner_form_category_2');
            const formCategoryBlockPrivate = document.getElementById('partner_form_sub-category-private');
            const formCategoryBlockPublic = document.getElementById('partner_form_sub-category-public');

            // partners form
            function updateFields(except){
                formCategoryBlockPublic.classList.add('hide');
                formCategoryBlockPrivate.classList.add('hide');
                except.classList.remove('hide');

                // prevent multiple radios from being checked
                var privateRadios = document.querySelectorAll('#partner_form_sub-category-private input');
                var publicRadios = document.querySelectorAll('#partner_form_sub-category-public input');
                var allRadios = [...privateRadios, ...publicRadios];

                for (var i = 0; i < allRadios.length; i++) {
                    allRadios[i].checked = false;
                }
            }

            formCategoryChoicePrivate.addEventListener('click', event => {
                updateFields(formCategoryBlockPrivate);
            });

            formCategoryChoicePublic.addEventListener('click', event => {
                updateFields(formCategoryBlockPublic);
            });

            formCategoryChoiceInstitute.addEventListener('click', event => {
                updateFields(formCategoryChoiceInstitute);
            });
        });
        break;
    case 'supporters':
        new SupporterIndex();
        break;
    case 'updates':
        new PageUpdates();
        break;
    case 'search':
        new Search();
        break;
    case 'projects':
        new ProjectIndex();
        break;
    case 'project-detail':
        new ProjectDetail();
        break;
    case 'project-form':
        new ProjectForm();
        break;
    case 'pilot':
        new PilotDetail();
        break;
    case 'themes-overview':
        new Actielijnen();
        break;
}

function setOnScroll(cb) {
	// Reference: http://www.html5rocks.com/en/tutorials/speed/animations/

	let last_known_scroll_position = 0;
	let ticking = false;

	window.addEventListener('scroll', e => {
		last_known_scroll_position = window.scrollY;

		if (!ticking) {
			window.requestAnimationFrame(() => {
				cb(last_known_scroll_position);
				ticking = false;
			});
			ticking = true;
		}
	});
}

$(document).ready(() => {
    const navMenuToggle = document.querySelector('.nav-menu-toggle');
    const topbar = document.querySelector('#topbar');

	window.scroll(0,-1); // scroll one pixel up on page load to trigger menu styling if user has scrolled before page refresh

	let scrollExceedsHeight = false;
	setOnScroll((pos) => {
		if (pos > topbar.getBoundingClientRect().height) {
			if (scrollExceedsHeight === false) {
				scrollExceedsHeight = true;
				topbar.classList.add('alternate');
			}
		} else {
			if (scrollExceedsHeight === true &&  navMenuToggle.checked == false ) {
				scrollExceedsHeight = false;
				topbar.classList.remove('alternate');
			}
		}
	})

	// simpleshare
	$('.simpleshare').simpleShare({ facebook: true, twitter: true, linkedin: true, whatsapp: false });

	smoothscroll.polyfill();

	//Modals.initModals(); initializes modals from existing HTML
	new FormValidator().processForms();

	new CookieConsent('ga', 'UA-xxxxxxxxx-x');

	// open external links in new window
	//$('.content-container').find('a[href ^= "http://"], a[href ^= "https://"]').attr('target', '_blank');

	// $('abbr[title], img[title], [data-definition]').each((i, el) => {
    //
	// 	const $definition = $(el);
	// 	let title = $definition.attr('title') ? $definition.attr('title') : $definition.find('.tooltip-content:first');
	// 	if (!title) return true;
	// 	//$definition.data('title', $definition.attr('title')); // get title attribute value
	// 	$definition.data('title', title); // add title attribute value to data-title attribute (when previous line had no success)
	// 	$definition.removeAttr('title'); // remove the title attribute, removing browser tooltip
    //
	// 	let tippyInst = tippy(el, {
	// 		content: title instanceof jQuery ? title.html() : title,
	// 		animation: 'scale-subtle',
	// 		theme: 'light',
	// 		interactive: 'true',
	// 		appendTo: document.body,
	// 		inlinePositioning: true,
	// 		allowHTML: true,
	// 		popperOptions: {
	// 			positionFixed: true
	// 		}
	// 		//showOnCreate: true
	// 	}, [inlinePositioning]);
	// 	$(el).data('tippy', tippyInst);
    // });
    
    // A class needs to be added to topbar in order to get a white background when mobile menu is click while scroll is at top of page

    navMenuToggle.addEventListener('click', event => {
        if (scrollExceedsHeight === false) {
            if ( navMenuToggle.checked == true ) {
                topbar.classList.add('alternate');
            } else {
                topbar.classList.remove('alternate');
            }
        }
    });

    // Utils.distributeInsects();

});
